<template>
  <main>
    <header-black />
    <about-company />
    <about-results />
    <about-description />
    <about-benefits />
  </main>
</template>

<script>
export default {
  name: 'AboutPage',
  components: {
    HeaderBlack: () => import('@/components/HeaderBlack'),
    AboutCompany: () => import('@/components/AboutCompany'),
    AboutResults: () => import('@/components/AboutResults'),
    AboutDescription: () => import('@/components/AboutDescription'),
    AboutBenefits: () => import('@/components/AboutBenefits')
  }
}
</script>
